<div class="wrapper">
  <div class="page-header page-header-small" filter-color="orange">
    <div class="page-header-image rellax-header" data-rellax-speed="-8"
      style="background-image: url('https://images.unsplash.com/photo-1491975474562-1f4e30bc9468?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80')">
    </div>
    <div class="container">
      <div class="content-center mt-5">
        <h5 class="title mb-3">
          Notifications
        </h5>

        <!-- <div class="row m-0">
          <div class="col-md-8 m-auto">
            <button class="btn btn-primary btn-round btn-sm" type="button">
              Office
            </button>
            <button class="btn btn-primary btn-round btn-sm" type="button">
              Retail
            </button>
            <button class="btn btn-primary btn-round btn-sm" type="button">
              Residential
            </button>
          </div>
        </div> -->
        <div class="row m-0">
          <div class="col-md-12 text-left text-dark">

            <ul class="list-group " *ngIf="Osds.length;else noNotification">
              <li class="list-group-item text-capitalize" *ngFor="let osd of Osds"><i class="fa fa-caret-right"
                  aria-hidden="true"></i>
                {{ osd.message }}</li>
            </ul>
            <ng-template #noNotification>
              <ul class="list-group">
                <li class="list-group-item text-capitalize"><i class="fa fa-caret-right" aria-hidden="true"></i> No new
                  notifications</li>
              </ul>
            </ng-template>
          </div>
          <!-- <div class="col-md-12">
            <p class="ml-3 mt-3 text-center">
              Note: Click search button to see sample Cards.
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <app-transparent-footer></app-transparent-footer>
  </div>



</div>
