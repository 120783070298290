import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-download-certificate',
    templateUrl: './download-certificate.component.html',
    styleUrls: ['./download-certificate.component.scss'],
})
export class DownloadCertificateComponent {
    certificateData: any;
    today = new Date();
    loading = false;

    constructor(private http: HttpClient) {
        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }

    downloadCertificate(frm: NgForm): any {
        // console.log(frm.value);
        this.loading = true;
        if (frm.value.mobile && frm.value.mobile.length === 10) {
            this.http
                .post(`${environment.url}/certificate/getList?mobile=${frm.value.mobile}`, {mobile: frm.value.mobile})
                .subscribe({
                    next: (res: any) => {
                      this.loading = false;
                      if (res.data.length) {
                        this.certificateData = res.data[0];
                        setTimeout(() => {
                          window.print();
                        }, 2000);
                      } else {
                        alert('No data found with this mobile!');
                      }
                    },
                    error: (err) => {
                      this.loading = false;
                        console.log(err);
                        alert('Something went wrong! please try again later.');
                    },
                });
        } else {
            alert('Enter valid mobile number!');
        }
    }
}
