<div class="page-header" filter-color="orange">
  <div
    class="page-header-image"
    style="
      background-image: url(https://images.unsplash.com/photo-1596496050755-c923e73e42e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1336&q=80);
    "
  ></div>
  <div class="container">
    <div class="col-md-4 content-center">
      <div class="card card-login card-plain">
        <form class="form" #signinForm="ngForm" novalidate autocomplete="off">
          <div class="header header-primary text-center">
            <div class="logo-container">
              <img src="assets/img/edu-logo-sq.png" alt="" [style.borderRadius]="'50%'"/>
            </div>
          </div>
          <div class="content">
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="now-ui-icons users_circle-08"></i
                ></span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Enrollment Number"
                ngModel
                id="enrollment_number"
                name="enrollment_number"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
              />
            </div>
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus1 === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text" (click)="toggleFieldTextType()"
                  ><i
                    class="fa"
                    [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }"
                  ></i
                ></span>
              </div>
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                name="password"
                placeholder="Enter Password"
                class="form-control"
                (focus)="focus1 = true"
                (blur)="focus1 = false"
                [ngClass]="{
                  'is-invalid':
                    !password.valid &&
                    (password.dirty || password.touched || isSubmit)
                }"
                #password="ngModel"
                required
                ngModel
                autocomplete="new-password"
              />
              <label
                *ngIf="
                  !password.valid &&
                  (password.dirty || password.touched || isSubmit)
                "
                class="
                  error
                  jquery-validation-error
                  small
                  form-text
                  invalid-feedback
                "
                >Please enter a valid password.</label
              >
            </div>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn btn-primary btn-round btn-lg btn-block"
              [disabled]="signinForm.form.invalid"
              (click)="login(signinForm)"
            >
              Login
            </button>
          </div>
          <div class="pull-left">
            <h6>
              <a (click)="signupRoute()" class="link">Create Account</a>
            </h6>
            <h6>
              <a (click)="forgotRoute()" class="link">Forgot Password</a>
            </h6>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-transparent-footer></app-transparent-footer>
</div>
