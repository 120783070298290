import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  focus;
  focus1;
  constructor(
    private alert: AlertService,
    private router: Router,
    private examService: ExamService
  ) { }

  submit(frm: NgForm) {
    if (frm.valid) {
      this.examService.forgotPassword(frm.value).subscribe((resp: any) => {
        if (resp.success) {
          // this.alert.successToast(resp.msg);
          this.router.navigate([resp.url]);
        } else {
          this.alert.errorToast(resp.msg);
        }
      });
    } else {
      this.alert.warningToast("Please fill form completly");
    }
  }
  ngOnInit(): void {
  }

}
