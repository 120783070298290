<div class="page-header" filter-color="orange">
    <div class="container">
        <div *ngIf="showConsentScreen">
            <div class="col-md-12 content-center">
                <div class="card card-login card-plain" style="max-width: none;">
                    <!-- Consent Form -->
                    <div class="content text-light" style="max-height: 60vh; overflow-y: scroll; margin-top: 125px;">
                        <!-- Consent Information -->
                        <div class="mb-4">
                            <h3 style="color: #ffc107;">डेटा साझा करने की सहमति</h3>
                            <p class="text-info">
                                मैं, इस फॉर्म के माध्यम से लिखित परीक्षा में भाग लेने के लिए अपनी सहमति प्रदान करता/करती
                                हूं। मुझे सूचित किया गया है कि परीक्षा के दौरान मेरी निम्नलिखित व्यक्तिगत जानकारी एकत्र
                                की जाएगी:
                            </p>
                            <p class="text-warning">
                                1.नाम,
                                2.जन्म तिथि,
                                3.शैक्षिक स्थिति,
                                4.फोन नंबर,
                                5.फोटो,
                                6.आधार नंबर
                            </p>
                            <p class="text-info">
                                मैं समझता/हूं कि मेरी व्यक्तिगत जानकारी को डिजिटल डेटा संरक्षण अधिनियम, 2016 के अनुसार
                                एकत्र, संग्रहीत, उपयोग और प्रकट किया जाएगा।
                            </p>
                            <p class="text-info">
                                <strong>निम्नलिखित उद्देश्यों के लिए मेरी जानकारी एकत्र की जा रही है:</strong>
                            </p>
                            <p class="text-warning">
                                1.परीक्षा आयोजित करना,
                                2.परीक्षा परिणाम प्रकाशित करना,
                                3.उम्मीदवारों की पहचान सत्यापित करना,
                                4.भविष्य में या रहे अवसरों के लिए मुझसे संपर्क हेतु
                            </p>
                            <p>
                                मैं समझता/हूं कि मेरी सहमति किसी भी समय वापस ली जा सकती है। हालाँकि, सहमति वापस लेने से
                                पहले एकत्र की गई जानकारी को प्रभावित नहीं किया जा सकता है।
                            </p>
                            <p>
                                मैं पुष्टि करता/करती हूं कि मैंने उपरोक्त जानकारी को पढ़ लिया/ली है और मैं अपनी सहमति
                                स्वतंत्र रूप से दे रहा/रही हूं।
                            </p>
                            <p>
                                <strong>दिनांक: {{ today | date:'mediumDate' }}</strong>
                            </p>
                            <p>
                                आप डिजिटल डेटा संरक्षण अधिनियम, 2016 के बारे में अधिक जानकारी भारत सरकार के वेबसाइट <a
                                    href="https://www.meity.gov.in/data-protection-framework"
                                    style="color: #17a2b8;">यहाँ</a> पर प्राप्त कर सकते हैं।
                            </p>
                        </div>

                        <!-- Consent Question and Options -->
                        <label for="consent" style="color: #ffc107;">क्या आप अपना डेटा साझा करने की सहमति देते
                            हैं?</label>
                        <div class="row justify-content-center mb-3">
                            <div class="col-auto">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="consent" id="consentYes"
                                        value="yes" required [(ngModel)]="consent">
                                    <label class="form-check-label" for="consentYes"
                                        style="color: #28a745; font-weight: bold;">हाँ</label>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="consent" id="consentNo"
                                        value="no" required [(ngModel)]="consent">
                                    <label class="form-check-label" for="consentNo"
                                        style="color: #dc3545; font-weight: bold;">नहीं</label>
                                </div>
                            </div>
                        </div>
                        <button type="button" (click)="onConsentSubmit()" class="btn btn-success mt-3">Submit</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-12 content-center" *ngIf="!showConsentScreen">
            <div class="card card-login card-plain" style="max-width: none;">
                <form class="form" #registerForm="ngForm" novalidate autocomplete="off" (submit)="submit(registerForm)">
                    <div class="header header-primary text-center">
                        <div class="logo-container">
                            <img src="assets/img/edu-logo-sq.png" alt="" />
                        </div>
                        <span class="text-muted">
                            दी गई जानकारी को सरकारी
                            दस्तावेजों ( जैसे 10 वीं प्रमाण पत्र, आधार कार्ड, आदि) के अनुसार भरें।

                            (Please fill the following information as per Govt Documents)
                        </span>
                    </div>

                    <div class="content" style="max-height: 60vh; overflow-y: scroll">
                        <span class="text-muted">
                            नाम (Full Name) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons users_single-02"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter full name" ngModel id="name"
                                name="name" autocomplete="off" (focus)="focus=true" (blur)="focus=false" required />
                        </div>

                        <span class="text-muted">
                            पिता (Father Name) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons users_circle-08"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Father's Name" ngModel id="father_name"
                                name="father_name" autocomplete="off" (focus)="focus = true" (blur)="focus = false"
                                required />
                        </div>

                        <span class="text-muted">
                            मोबाइल (Mobile Number) *
                            <br />
                            <span>(Mobile No should be 10 digits)</span>
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons gestures_tap-01"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="9927******" ngModel id="mobile"
                                name="mobile" autocomplete="off" (focus)="focus = true" (blur)="focus = false"
                                required />
                        </div>

                        <span class="text-muted">
                            जन्मतिथि (Date Of Birth) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons gestures_tap-01"></i>
                                </span>
                            </div>
                            <input type="date" class="form-control" placeholder="12/09/1996" ngModel id="dob" name="dob"
                                autocomplete="off" (focus)="focus = true" (blur)="focus = false" required />
                        </div>

                        <span class="text-muted">
                            ईमेल (Email)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="now-ui-icons ui-1_email-85" aria-hidden="true"></i>
                                </span>
                            </div>
                            <input type="email" class="form-control" placeholder="Enter Email" ngModel id="email"
                                name="email" autocomplete="off" (focus)="focus = true" (blur)="focus = false" />
                        </div>

                        <span class="text-muted">
                            योग्यता (Qualification) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons business_briefcase-24" aria-hidden=" true"></i>
                                </span>
                            </div>
                            <select class="form-control" ngModel id="qualification" (focus)="focus = true"
                                (blur)="focus = false" name="qualification" required>
                                <option value="" disabled selected>Select Qualification</option>
                                <option class="text-dark">10th</option>
                                <option class="text-dark">12th</option>
                                <option class="text-dark">Diploma</option>
                                <option class="text-dark">Graduate</option>
                                <option class="text-dark">Post Graduate</option>
                            </select>
                        </div>

                        <span class="text-muted">
                            वैवाहिक स्थिति (Maritial Status)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons business_briefcase-24" aria-hidden=" true"></i>
                                </span>
                            </div>
                            <select class="form-control" ngModel id="maritial_status" (focus)="focus = true"
                                (blur)="focus = false" name="maritial_status">
                                <option class="text-dark" value="" disabled selected>Select</option>
                                <option class="text-dark" value="unmarried">अविवाहित</option>
                                <option class="text-dark" value="married">विवाहित</option>
                                <option class="text-dark" value="divorced">तलाकशुदा</option>
                                <option class="text-dark" value="widowed">विधवा</option>
                            </select>
                        </div>

                        <span class="text-muted">
                            राज्य (State) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons business_briefcase-24" aria-hidden=" true"></i>
                                </span>
                            </div>
                            <select class="form-control" [(ngModel)]="state" id="state" name="state"
                                (ngModelChange)="getDistricts()" required>
                                <option value="" disabled selected>Select State</option>
                                <option class="text-dark" *ngFor="let st of states">{{st.state}}</option>
                            </select>
                        </div>

                        <span class="text-muted">
                            जिला (District) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons business_briefcase-24" aria-hidden=" true"></i>
                                </span>
                            </div>
                            <select class="form-control" ngModel id="city" (focus)="focus = true" (blur)="focus = false"
                                name="city" required>
                                <option value="" disabled selected>Select District</option>
                                <option class="text-dark" *ngFor="let dis of districts">{{dis}}</option>
                            </select>
                        </div>

                        <span class="text-muted">
                            प्रखंड (Block)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons business_briefcase-24" aria-hidden=" true"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter block name" ngModel id="block"
                                name="block" autocomplete="off" (focus)="focus = true" (blur)="focus = false" />
                        </div>

                        <span class="text-muted">
                            पंचायत (Panchayat)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons travel_info"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter Panchayat Name" ngModel
                                id="panchayat" name="panchayat" autocomplete="off" (focus)="focus = true"
                                (blur)="focus = false" />
                        </div>

                        <span class="text-muted">
                            गाँव (Village)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons travel_info"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter Village" ngModel id="village"
                                name="village" autocomplete="off" (focus)="focus = true" (blur)="focus = false" />
                        </div>


                        <span class="text-muted">
                            विद्यालय (College/School)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons travel_info"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter College/School" ngModel
                                id="college_name" name="college_name" autocomplete="off" (focus)="focus = true"
                                (blur)="focus = false" />
                        </div>

                        <span class="text-muted">
                            अगर घर में किसी के फोन में whatsapp चलता है तो वो नंबर लिखें
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons gestures_tap-01"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter Whats app number" ngModel
                                id="whats_app_no" name="whats_app_no" autocomplete="off" (focus)="focus = true"
                                (blur)="focus = false" />
                        </div>

                        <div class="form-group">
                            <label for="consent"> अगर मेरा selection नहीं हुआ तो भी मैं i-सक्षम के लर्निंग पोर्टल से
                                जुड़कर सीखना चाहूँगी *</label>
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="consent_learning"
                                            id="consentYes" value="yes" required ngModel>
                                        <label class="form-check-label" for="consentYes"> Yes </label>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="consent_learning"
                                            id="consentNo" value="no" required ngModel>
                                        <label class="form-check-label" for="consentNo"> No </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="nextYearPreparation"> अगर मेरा selection नहीं हुआ तो मैं अगले साल select
                                होने के
                                लिए तैयारी करना चाहती हूँ। *</label>
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="nextYearPreparation"
                                            id="nextYearPreparationYes" value="yes" required ngModel>
                                        <label class="form-check-label" for="nextYearPreparationYes"> Yes </label>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="nextYearPreparation"
                                            id="nextYearPreparationNo" value="no" required ngModel>
                                        <label class="form-check-label" for="nextYearPreparationNo"> No </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <span class="text-muted">
                            क्लस्टर (Cluster)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons travel_info"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter cluster name" ngModel
                                id="cluster" name="cluster" autocomplete="off" (focus)="focus = true"
                                (blur)="focus = false" />
                        </div>

                        <span class="text-muted">
                            समन्वयक (Team Cordinator Name) *
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons gestures_tap-01"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter Team Co-ordinator name" ngModel
                                id="team_coordinator" name="team_coordinator" autocomplete="off" (focus)="focus = true"
                                (blur)="focus = false" required />
                        </div>

                        <span class="text-muted">
                            प्रस्तावित करने वाले का नाम (Recommanded by)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons gestures_tap-01"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Enter name" ngModel id="recommanded_by"
                                name="recommanded_by" autocomplete="off" (focus)="focus = true"
                                (blur)="focus = false" />
                        </div>

                        <span class="text-muted">
                            प्रस्तावित करने वाले का समूह (Select Recommanded Group)
                        </span>
                        <div class="input-group form-group-no-border input-lg"
                            [ngClass]="{ 'input-group-focus': focus === true }">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-light">
                                    <i class="now-ui-icons business_briefcase-24" aria-hidden=" true"></i>
                                </span>
                            </div>
                            <select class="form-control" ngModel id="recommanded_group" (focus)="focus = true"
                                (blur)="focus = false" name="recommanded_group">
                                <option value="" disabled class="text-dark">Select</option>
                                <option value="self-help-group" class="text-dark">स्वयं-सहायता समूह</option>
                                <option value="edu-leader" class="text-dark">एडु-लीडर</option>
                                <option value="school" class="text-dark">विद्यालय</option>
                                <option value="cluster" class="text-dark">क्लस्टर</option>
                                <option value="panchayati-raj-institution" class="text-dark">पंचायती राज संस्थान
                                </option>
                                <option value="other" class="text-dark">अन्य</option>
                            </select>
                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary btn-round btn-lg btn-block">
                            Create Account
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-transparent-footer></app-transparent-footer>