<nav class="navbar navbar-expand-lg bg-primary fixed-top navbar-transparent" color-on-scroll="500">
  <div class="container">
    <div class="navbar-translate">
      <a class="navbar-brand" [routerLink]="['/profile']">
        Candidate Portal
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
        (click)="sidebarToggle()">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" data-nav-image="assets/img/blurred-image-1.jpg" data-color="orange">
      <ul class="navbar-nav ml-auto">

        <li class="nav-item">
          <a class="nav-link btn btn-neutral" routerLink="/support">
            <i class="now-ui-icons arrows-1_share-66"></i>
            <p>Support</p>
          </a>
        </li>

        <li class="nav-item" *ngIf="!isLogIn() && isLoginPage()">

          <a class="nav-link btn-neutral btn" rel="tooltip" title="Login" data-placement="bottom" routerLink="/login">
            <i class="fas fab fa-sign-in-alt"></i>
            <p>Login</p>
          </a>
        </li>


        <li class="nav-item" *ngIf="isLogIn()">

          <a class="nav-link" rel="tooltip" title="Login" data-placement="bottom" (click)="logout()">

            <i class="fas fab fa-sign-out-alt    "></i>
            <p>Logout</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
