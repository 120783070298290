import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as Rellax from 'rellax'
import { environment } from 'src/environments/environment'
import { AuthService } from '../services/auth.service'
import { ExamService } from '../services/exam.service'

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  data: Date = new Date()
  focus
  focus1
  page = 1
  result: boolean
  env: any
  img =
    'https://images.unsplash.com/photo-1590694709385-125b83bff9de?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c3F1YXJlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60'
  currentURL: string
  Osds: any = []
  isLoggedin = false
  constructor(
    private router: Router,
    private examService: ExamService,
    private auth: AuthService,
  ) {
    this.isLoggedin = this.auth.isLoggedIn()
    if (this.isLoggedin) {
      this.router.navigate(['/profile'])
    } else {
      this.router.navigate(['/login'])
    }
  }

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header')

    var body = document.getElementsByTagName('body')[0]
    body.classList.add('landing-page')
    var navbar = document.getElementsByTagName('nav')[0]
    navbar.classList.add('navbar-transparent')
    this.getData()
  }
  getData() {
    this.examService.getOsds({}).subscribe((resp: any) => {
      if (resp.success) {
        this.Osds = resp.result
      }
    })
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0]
    body.classList.remove('landing-page')
    var navbar = document.getElementsByTagName('nav')[0]
    navbar.classList.remove('navbar-transparent')
  }
}
