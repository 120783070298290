import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';
import { AlertService } from 'src/app/services/alert.service';
import { ExamService } from 'src/app/services/exam.service';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  zoom: number = 14;
  lat: number = 44.445248;
  lng: number = 26.099672;
  styles: any[] = [{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }];
  data: Date = new Date();
  focus;
  focus1;
  issues = [];
  issuesCount = 0;
  pendingCount = 0;
  resolvedCount = 0;
  p = 1;
  limit = 5;
  issueList = [
    "All paper code for which I have to give exam is not available",
    "Subject name is not shown",
    "Paper status showing not available and start button is not visible",
    "Start Button is not highlighting",
    "Paper code is showing wrong after clicking on start button",
    "Question or option are not available",
    "Exam is not schedules is showing",
    "Camera Permission has been given still paper is not visible",
    "Father name is showing on dashboard rather than student name",
    "Automatically paper got submitted",
  ];
  closeResult: string;
  showContent: boolean = false;
  constructor(
    private modalService: NgbModal,
    private examService: ExamService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('profile-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
    this.getIssues();
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('profile-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }




  getIssues() {
    this.examService
      .getIssues({ page: this.p, limit: this.limit })
      .subscribe((resp: any) => {
        if (resp.success) {
          this.issues = resp.result;
          this.issuesCount = resp.resultCount;
          this.pendingCount = resp.pendingCount;
          this.resolvedCount = resp.resolvedCount;


        } else {
          this.alertService.infoToast("Try Again Later!");
        }
      });
  }

  submitReply(id: any) {
    const reply: any = document.getElementById("reply_" + id);
    if (reply.value) {
      this.examService
        .setIssueReply({ issue_id: id, reply: reply.value })
        .subscribe((resp: any) => {
          if (resp.success) {
            this.alertService.successToast("Reply submitted!");
            this.getIssues();
          } else {
            this.alertService.infoToast("Try Again Later!");
          }
        });
    }
  }

  submitIssue(frm: NgForm) {
    if (frm.value.category && frm.value.message) {
      this.examService
        .submitIssue({
          category: frm.value.category,
          message: frm.value.message,
        })
        .subscribe((resp: any) => {
          if (resp.success) {
            this.alertService.successToast("Issue submitted!");
            this.getIssues();
            frm.reset();
            this.modalService.dismissAll();
          } else {
            this.alertService.infoToast("Try Again Later!");
          }
        });
    }
  }
  open(content, type, modalDimension) {
    if (modalDimension == undefined && type === 'Login') {
      this.modalService.open(content, { windowClass: 'modal-login modal-primary' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  toggle(uid: string) {
    const elem = document.getElementById(uid);
    elem.classList.toggle('d-none');

  }
}
