import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router'
// import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from './alert.service'
import { TokenService } from './token.service'
import { AuthService } from './auth.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public tokenService: TokenService,
    public router: Router,
    // private spinner: NgxSpinnerService,
    private as: AlertService,
    private auth: AuthService,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.tokenService.token = localStorage.getItem('token')
    if (this.tokenService.getToken()) {
      if (!this.tokenService.isTokenExpired()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.tokenService.getToken()}`,
          },
        })
      } else {
        this.as.warningToast('Session Expired! Please Login')
        this.auth.logout()
      }
    }
    // this.spinner.show();
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
            return next.handle(request)
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.spinner.hide();
            if (err.status === 401) {
              this.as.errorToast(err.error.error.message + ' Please Login')
              this.auth.cleanUserData()
              this.router.navigate(['/login'])
            } else if (err.status === 0) {
              this.as.errorToast(`Server Unavailable! (${err.status})`)
            } else {
              this.as.errorToast(err.error.error.message)
            }
            // else {
            //   // this.as.errorToast(err.error.error.message);
            // }
          }
        },
        () => {
          // this.spinner.hide();
        },
      ),
    )
  }
}
