import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { ExamService } from 'src/app/services/exam.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  data: Date = new Date();
  fieldTextType: boolean;

  form: any;


  focus;
  focus1;
  public isSubmit: boolean;
  currentUser: any;
  id: any;
  name: any;
  password_reset_id: any;
  constructor(
    private router: Router,
    private as: AlertService,
    public authService: AuthService,
    private tokenService: TokenService,
    private activatedroute: ActivatedRoute,
    private examService: ExamService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.activatedroute.params.subscribe(param => {
      this.password_reset_id = param.token
    })
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  refreshPage() {
    this._document.defaultView.location.reload();
  }
  submit(frm: NgForm) {
    if (frm.valid) {

      if (frm.value.password !== frm.value.confirm_password) {
        return this.as.warningToast('Password doesn\'t match.');
      }
      frm.value.password_reset_id = this.password_reset_id;
      this.examService.resetPassword(frm.value)
        .subscribe((resp: any) => {
          if (resp.success) {
            this.as.successToast(resp.msg);
            this.router.navigate(['/login']);
            setTimeout(() => {
              this.refreshPage();
            }, 1000);
          } else {
            this.as.errorToast(resp.msg);
          }
        })
    } else {
      this.as.warningToast('Please fill form completly');
    }
  }

}
