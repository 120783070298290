import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { TokenService } from 'src/app/services/token.service';
export class FormInput {
  enrollment_number: any;
  password: any;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  data: Date = new Date();
  fieldTextType: boolean;
  formInput: FormInput;
  form: any;

  focus;
  focus1;
  public isSubmit: boolean;
  currentUser: any;
  id: any;
  name: any;
  constructor(
    private router: Router,
    private as: AlertService,
    public authService: AuthService,
    private tokenService: TokenService,
    private activatedroute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.isSubmit = false;
    const isLoggedIn = this.authService.isLoggedIn();
    if (isLoggedIn) {
      router.navigate(['/profile']);
    }
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  ngOnInit() {
    this.formInput = {
      enrollment_number: '',
      password: '',
    };

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  signupRoute() {
    this.router.navigate(['/sregister']);
  }

  forgotRoute() {
    this.router.navigate(['/forgot-password']);
  }

  login(frm: NgForm) {
    console.log('----------------');
    if (!frm.valid) {
      this.as.warningToast('Please fill all the fields properly!');
      return;
    }
    this.authService.login(frm.value).subscribe(
      (resp: any) => {
        if (resp.success) {
          localStorage.setItem('Token', resp.accessToken);
          localStorage.setItem('refershToken', resp.refreshToken);
          localStorage.setItem('user', JSON.stringify(resp.user));
          this.router.navigate(['/profile']);
          this.refreshPage();
        }
      },
      (err) => {
        this.as.infoToast(err.error.error.message);
      }
    );
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }
}
