import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './admin/profile/profile.component';
import { SupportComponent } from './admin/support/support.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ResisterComponent } from './auth/register/resister.component';
import { ErrorNotFoundComponent } from './error-not-found/error-not-found.component';
import { LandingComponent } from './landing/landing.component';
import { PracticalComponent } from './admin/practical/practical.component';
import { DownloadCertificateComponent } from './auth/download-certificate/download-certificate.component';
import { VivaComponent } from './admin/viva/viva.component';
import { ConsentWithRegisterComponent } from './auth/consent-with-register/consent-with-register.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        component: LandingComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'register',
        component: ResisterComponent,
    },
    {
        path: 'sregister',
        component: ConsentWithRegisterComponent,
    },
    {
        path: 'forgot-password',
        component: ForgetPasswordComponent,
    },
    {
        path: 'download-certificate',
        component: DownloadCertificateComponent,
    },
    {
        path: 'rstpwd/:token',
        component: ResetPasswordComponent,
    },
    {
        path: 'profile',
        component: ProfileComponent,
    },
    {
        path: 'practical',
        component: PracticalComponent,
    },
    {
        path: 'viva',
        component: VivaComponent,
    },
    {
        path: 'support',
        component: SupportComponent,
    },
    { path: '**', component: ErrorNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
