import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-practical',
  templateUrl: './practical.component.html',
  styleUrls: ['./practical.component.scss'],
})
export class PracticalComponent implements OnInit, OnDestroy {
  Intervals = [];
  PracticalQuestions: any;

  constructor(private examService: ExamService) {}

  ngOnInit(): void {
    this.Intervals.push(
      setInterval(() => {
        this.examService.getPracticalQuestions().subscribe((resp: any) => {
          this.PracticalQuestions = resp;
        });
      }, 3000)
    );
  }

  ngOnDestroy(): void {
    for (const intv of this.Intervals) {
      clearInterval(intv);
    }
  }

  getMeetingUrl() {
    this.examService.getMeetingUrl().subscribe((resp: any) => {
      if (resp.status) {
        window.open(resp.data);
      }else{
        alert('Meeting can\'t be started now')
      }
    });
  }
}
