import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.css']
})
export class ErrorNotFoundComponent implements OnInit, OnDestroy {

  constructor(
    private router : Router,
  ) { }

  ngOnInit(): void {
 
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('cgl');
  }
  homePage() {
    this.router.navigate (['/home'])
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("cgl");
  }
}
