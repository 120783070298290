import { Component, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/services/alert.service'
import { ExamService } from 'src/app/services/exam.service'
import * as jsonData from 'src/assets/json/state_district_city.json'

interface State {
  state: string
  districts: string[]
}

@Component({
  selector: 'app-consent-with-register',
  templateUrl: './consent-with-register.component.html',
  styleUrls: ['./consent-with-register.component.scss'],
})
export class ConsentWithRegisterComponent implements OnInit {
  focus
  focus1
  states: State[]
  state: String = 'Bihar'
  districts: string[]
  showConsentScreen: boolean = true // Initially show consent screen
  consent: string = '' // Track user's consent
  today = Date.now()
  constructor(
    private alert: AlertService,
    private router: Router,
    private examService: ExamService,
  ) {}

  ngOnInit(): void {
    var body = document.getElementsByTagName('body')[0]
    body.classList.add('login-page')

    var navbar = document.getElementsByTagName('nav')[0]
    navbar.classList.add('navbar-transparent')

    this.states = jsonData.states
    this.state = 'Bihar'; // Set default state
    this.getDistricts();
  }

  getDistricts() {
    this.districts = []
    this.districts =
      jsonData.states.find((s: State) => s.state === this.state)?.districts ||
      []
  }

  onConsentSubmit() {
    if (this.consent === 'yes') {
      this.showConsentScreen = false
    } else {
      window.location.href = 'https://sgsuniversity.ac.in/'
    }
  }

  refreshPage() {
    this.router.navigate(['/profile'])
  }

  submit(frm: NgForm) {
    if (!frm.value.name) {
      this.alert.errorToast('नाम दर्ज करें')
      return
    }
    if (!frm.value.father_name) {
      this.alert.errorToast('पिता का नाम दर्ज करें')
      return
    }

    if (!frm.value.mobile) {
      this.alert.errorToast('मोबाइल नंबर दर्ज करें')
      return
    }
    if (!/^[6-9]\d{9}$/.test(frm.value.mobile)) {
      this.alert.errorToast('अमान्य मोबाइल नंबर')
      return
    }
    if (!frm.value.dob) {
      this.alert.errorToast('जन्मतिथि दर्ज करें')
      return
    }
    if (!frm.value.qualification) {
      this.alert.errorToast('योग्यता दर्ज करें')
      return
    }
    if (!frm.value.state) {
      this.alert.errorToast('राज्य दर्ज करें')
      return
    }
    if (!frm.value.city) {
      this.alert.errorToast('जिला दर्ज करें')
      return
    }
    const data = {
      name: frm.value.name,
      father_name: frm.value.father_name,
      enrollment_number: frm.value.mobile,
      dob: frm.value.dob ? frm.value.dob : '2000-01-01',
      mobile: frm.value.mobile ? frm.value.mobile : '9999999999',
      email: frm.value.email ? frm.value.email : 'dummmy@abc.com',
      password: frm.value.mobile ? frm.value.mobile : 5607,
      college_name: frm.value.college_name ? frm.value.college_name : '-',
      state: frm.value.state ? frm.value.state : this.state,
      city: frm.value.city ? frm.value.city : '-',
      block: frm.value.block ? frm.value.block : '-',
      cluster: frm.value.cluster ? frm.value.cluster : '-',
      village: frm.value.village ? frm.value.village : '-',
      additional_details: {
        whats_app_no: frm.value.whats_app_no ? frm.value.whats_app_no : '-',
        consent_learning: frm.value.consent_learning
          ? frm.value.consent_learning
          : '-',
        nextYearPreparation: frm.value.nextYearPreparation
          ? frm.value.nextYearPreparation
          : '-',
        qualification: frm.value.qualification ? frm.value.qualification : '-',
        consent: this.consent,
        team_coordinator: frm.value.team_coordinator
          ? frm.value.team_coordinator
          : 'I-Saksham',
        panchayat: frm.value.panchayat ? frm.value.panchayat : '-',
        maritial_status: frm.value.maritial_status
          ? frm.value.maritial_status
          : 'unmarried',
        recommanded_by: frm.value.recommanded_by
          ? frm.value.recommanded_by
          : '-',
        recommanded_group: frm.value.recommanded_group
          ? frm.value.recommanded_group
          : 'other',
      },
    }
    this.examService.registerForSaksham(data).subscribe((resp: any) => {
      if (resp.success) {
        this.alert.successToast(resp.msg)
        localStorage.setItem('Token', resp.accessToken)
        localStorage.setItem('refershToken', resp.refreshToken)
        localStorage.setItem('user', JSON.stringify(resp.user))
        this.router.navigate(['/profile'])
        this.refreshPage()
      } else {
        this.alert.errorToast(resp.msg)
      }
    })
  }
}
