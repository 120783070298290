import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  constructor(private http: HttpClient) {}

  dashboardSummary() {
    return this.http.get(`${environment.url}/exam/dashboardSummary`)
  }

  getPracticalQuestions() {
    return this.http.get(`${environment.url}/exam/getPracticalQuestions`)
  }

  getVivaQuestions() {
    return this.http.get(`${environment.url}/exam/getVivaQuestions`)
  }

  registerMail(frmData: any) {
    return this.http.post(`${environment.url}/exam/registermail`, frmData)
  }

  registerForSaksham(frmData: any) {
    return this.http.post(`${environment.url}/exam/registerForSaksham`, frmData)
  }

  forgotPassword(frmData: any) {
    return this.http.post(`${environment.url}/exam/forgotpassword`, frmData)
  }

  resetPassword(frmData: any) {
    return this.http.post(`${environment.url}/exam/resetpassword`, frmData)
  }

  submitIssue(frmData: any) {
    return this.http.post(`${environment.url}/exam/submit_issue`, frmData)
  }

  getIssues(frmData: any) {
    return this.http.post(`${environment.url}/exam/get_issues`, frmData)
  }

  setIssueReply(frmData: any) {
    return this.http.post(`${environment.url}/exam/set_issue_reply`, frmData)
  }

  getOsds(frmData: any) {
    return this.http.post(`${environment.url}/exam/getOsds`, frmData)
  }

  isQpExam(code: any) {
    return this.http.post(`${environment.url}/exam/isQpExam/`, { code: code })
  }

  getMeetingUrl() {
    return this.http.post(`${environment.url}/exam/attendeeJoinMeetingUrl`, {})
  }

  getVivaMeetingUrl() {
    return this.http.post(
      `${environment.url}/exam/attendeeJoinMeetingVivaUrl`,
      {},
    )
  }

  getuploadOfflineExamFile() {
    return this.http.get(`${environment.url}/exam/getuploadOfflineExamFile`)
  }

  getuploadOnlineExamFile() {
    return this.http.get(`${environment.url}/exam/getuploadOnlineExamFile`)
  }

  uploadOfflineExamFile(file: File) {
    const fd = new FormData()
    fd.append('file', file, file.name)

    return this.http.post(environment.url + '/exam/uploadOfflineExamFile', fd, {
      reportProgress: true,
      observe: 'events',
    })
  }

  uploadPhotoFile(file: File) {
    const fd = new FormData()
    fd.append('file', file, file.name)

    return this.http.post(environment.url + '/exam/uploadPhotoFile', fd, {
      reportProgress: true,
      observe: 'events',
    })
  }
}
