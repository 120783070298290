<div *ngIf="certificateData" class="certificate-page">
    <button id="printPageButton" class="badge badge-danger rouded-circle" (click)="certificateData=''">
        <h1 class="m-0">
            &times;
        </h1>
    </button>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="container">
            <page size="A4" class="page printonly">
                <div class="outer-border">
                  <div class="center-border">
                    <div class="inner-border">

                      <div class="row mb-4">
                        <div class="col-3 mt-3 text-center">
                          <!-- <b >Training Partner</b>
                              <img src="./assets/img/brand/Picture2.png" alt="Synergie" /> -->
                        </div>
                        <div class="col-6 text-center mb-3 pt-5">
                          <img src="assets/img/AISECT-Logo.jpg" alt="Logo" height="200px" />
                        </div>
                        <div class="col-3 text-center pt-3">
                          <!-- <span>Serial No:NEEM/20-21/0045</span> -->
                          <!-- <b >A CSR Initiative by</b>
                              <img src="./assets/img/brand/Castrol_logo.png" alt="Logo" height="50px"/> -->
                        </div>
                      </div>
                      <h2 class="text-center mt-5">
                        National Employability Enhancemen Mission
                        <br>
                        (A skill development initiative by AICTE)
                      </h2>
                      <div class="row sans text-center mx-3 px-5">
                        <div class="text-justify" style="font-size: 24px; line-height: 40px;">
                          <!-- <span> -->
                          This is to certify that Mr/Ms. <span class="text-uppercase"><b>{{certificateData.Name}}</b></span> has successfully
                          completed the Soft Skills Training with
                          <span class="text-uppercase"><b>{{certificateData['Company Name']}}</b></span>, Which is registered with <span
                            class="text-capitalize"><b>All India Council of technical Education (AICTE)</b></span> to impart Skill
                          training under National Employability Enhancement Mission (NEEM). Mr./Ms.<span class="text-uppercase"><b> {{certificateData.Name}} </b></span> successfully
                          completed the NEEM training in <span
                          class="text-capitalize"><b> {{certificateData.Sector}}</b></span> at<span
                            class="text-capitalize"><b>
                                {{certificateData['Company Address']}}</b></span>.

                          <!-- </span> -->
                         <br>
                          <br>
                          Duration of the training was from <b>{{certificateData['Date of Joining'] | date: 'dd/MM/yy'}} </b>to <b>{{certificateData['Date of Completion'] | date: 'dd/MM/yy'}}</b>.
                          <br>
                          <br>
                          Congratuations on your distinctive performance!
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-6 mt-5 " style="font-size: 24px;">
                          <div class="m-4 text-left ">
                            <span> NEEM Id- </span>
                            <span>
                              <b>{{certificateData['Neem ID']}}</b>
                            </span>
                          <br>
                            <span>
                            Date of issuance-
                            <b>{{today | date: 'dd/MM/yy'}}</b>

                          </span>
                          </div>
                        </div>
                        <div class="col-6  " style="font-size: 24px;">
                          <div class="float-right text-center m-5">
                            <span class="mb-5">Authorised Signatory</span>
                            <br>
                            <img src="assets/img/authSignauth.png">
                            <br>
                            <span><b>
                                Kumar Kashyap<br>
                                (Head: Apprentices and NEEM)
                              </b></span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </page>
        </div>
        <!-- <div class="container">
            <h2 class="text-center" style="font-weight: 900;">
                National Employability Enhancement Mission
                <br>
                (A Skill Development Initiative by AICTE)
            </h2>
            <h3 class="text-center">
                <b>
                    <u>
                        Certificate of Training
                    </u>
                </b>
            </h3>
            <br>
            <br>
            <br>
            <p class="text-justify">
                This is to certify that <b><u>{{certificateData.Name}}</u></b> was registered as NEEM Trainee with <b><u>{{certificateData['Company Name']}}</u></b> which is registered with All India Council of
                technical Education (AICTE) to impart skill training under National Employability Enhancement
                Mission (NEEM). <b><u>{{certificateData.Name}}</u></b> has successfully completed the NEEM training in <b><u>{{certificateData.Sector}}</u></b> at premises of <b><u>{{certificateData['Company Address']}}</u></b>.
            </p>
            <p class="text-justify">
                Duration of the training was from <b><u>{{certificateData['Date of Joining'] | date: 'dd/MM/yy'}}</u></b> to <b><u>{{certificateData['Date of Completion'] | date: 'dd/MM/yy'}}</u></b>.
            </p>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col">
                    <b>
                        Trainee ID: {{certificateData['Neem ID']}}
                    </b>
                    <br>
                    <br>
                    <br>
                    <b>
                        Date of Issuance: {{today | date: 'dd/MM/yy'}}
                    </b>
                </div>
                <div class="col"></div>
                <div class="col">
                    <b>
                        Authorised Signatory
                    </b>
                    <br>
                    <br>
                    <br>
                    <b>
                        Kumar Kashyap
                    </b>
                    <br>
                    <br>
                    <b>
                        HEAD: Apprentice & NEEM
                    </b>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div *ngIf="!certificateData" class="page-header" filter-color="orange">
    <div class="page-header-image"
        style="background-image:url(https://images.unsplash.com/photo-1596496050755-c923e73e42e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1336&q=80)">
    </div>
    <div class="container">
        <div class="col-md-4 content-center">
            <div class="card card-login card-plain">
                <form #certificateForm="ngForm" (ngSubmit)="downloadCertificate(certificateForm)">
                    <div class="header header-primary text-center">
                        <div class="logo-container">
                            <img src="assets/img/edu-logo-sq.png" alt="">
                        </div>
                        <p>
                            Please enter registered mobile number to download the certificate here:
                        </p>
                    </div>
                    <div class="content mt-2 border-top border-white p-3 rounded">
                        <div class="form-group">
                            <label for="mobile">Mobile Number:</label>
                            <input #mobile type="text" ngModel autocomplete="off" class="form-control" name="mobile" id="mobile"
                                placeholder="Enter Mobile Number">
                                {{mobile.value}}
                        </div>
                        <div>
                            <button class="btn btn-primary" *ngIf="!loading">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download Certificate
                            </button>
                            <button type="button" class="btn btn-primary" *ngIf="loading">
                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                Fetching...
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-transparent-footer></app-transparent-footer>
</div>