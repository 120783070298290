import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from "ngx-pagination";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './auth/login/login.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/token-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { FooterComponent } from './shared/footer/footer.component';
import { TransparentFooterComponent } from './shared/transparent-footer/transparent-footer.component';

import { AdminNavComponent } from './shared/admin-nav/admin-nav.component';
import { ErrorNotFoundComponent } from './error-not-found/error-not-found.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { SupportComponent } from './admin/support/support.component';
import { ResisterComponent } from './auth/register/resister.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PracticalComponent } from './admin/practical/practical.component';
import { WebcamModule } from 'ngx-webcam';
import { DownloadCertificateComponent } from './auth/download-certificate/download-certificate.component';
import { VivaComponent } from './admin/viva/viva.component';
import { ConsentWithRegisterComponent } from './auth/consent-with-register/consent-with-register.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    LoginComponent,
    FooterComponent,
    TransparentFooterComponent,
    AdminNavComponent,
    ErrorNotFoundComponent,
    ProfileComponent,
    SupportComponent,
    ResisterComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    PracticalComponent,
    DownloadCertificateComponent,
    VivaComponent,
    ConsentWithRegisterComponent


  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgbModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    WebcamModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
