<div class="page-header" filter-color="orange">
  <div
    class="page-header-image"
    style="
      background-image: url(https://images.unsplash.com/photo-1596496050755-c923e73e42e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1336&q=80);
    "
  ></div>
  <div class="container">
    <div class="col-md-12 content-center">
      <div class="card card-login card-plain">
        <form
          class="form"
          #registerForm="ngForm"
          novalidate
          autocomplete="off"
          (submit)="submit(registerForm)"
        >
          <div class="header header-primary text-center">
            <div class="logo-container">
              <img src="assets/img/edu-logo-sq.png" alt="" />
            </div>
            <span class="text-muted">
              (Please fill the following information as per Govt Documents)
            </span>
          </div>
          <div class="content">
            <span class="text-muted">
              Enter Full Name
            </span>
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="now-ui-icons users_single-02"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter full name"
                ngModel
                id="name"
                name="name"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
                required
              />
            </div>
            <span class="text-muted">
              Enter Father Name
            </span>
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="now-ui-icons users_circle-08"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Father's Name"
                ngModel
                id="father_name"
                name="father_name"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
                required
              />
            </div>
            <span class="text-muted">
              Enter Mobile Number
              <br />
              <span>(Mobile No should be 10 digits)</span>
            </span>
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="now-ui-icons gestures_tap-01"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="9927******"
                ngModel
                id="mobile"
                name="mobile"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
                required
              />
            </div>
            <span class="text-muted">
              Enter Username
              <br>
              <span>Username must be unique (For Ex - adi@101)</span>
            </span>
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="now-ui-icons travel_info"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Username"
                ngModel
                id="enrollment_number"
                name="enrollment_number"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
                required
              />
            </div>
            <span class="text-muted">
              Enter Email
              <br>
              <span>Please enter valid email</span>
            </span>
            <div
              class="input-group form-group-no-border input-lg"
              [ngClass]="{ 'input-group-focus': focus === true }"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="now-ui-icons ui-1_email-85" aria-hidden="true"></i>
                </span>
              </div>
              <input
                type="email"
                class="form-control"
                placeholder="Enter Email"
                ngModel
                id="email"
                name="email"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
                required
              />
            </div>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn btn-primary btn-round btn-lg btn-block"
              [disabled]="registerForm.form.invalid"
            >
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-transparent-footer></app-transparent-footer>
