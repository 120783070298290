<div class="wrapper">
  <div class="page-header page-header-small" filter-color="orange">
    <div class="page-header-image rellax-header" data-rellax-speed="-8"
      style="background-image: url('https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80') ;">
    </div>
    <div class="container">
      <div class="content-center">

        <h3 class="title">Support</h3>
        <div class="content">
          <div class="social-description">
            <h2>{{issuesCount}}</h2>
            <p>Total Complaints</p>
          </div>
          <div class="social-description">
            <h2>{{pendingCount}}</h2>
            <p>Pending</p>
          </div>
          <div class="social-description">
            <h2>{{resolvedCount}}</h2>
            <p>Resolved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="button-container">
        <button (click)="open(classic,'' , '')" class="btn btn-primary btn-round btn-lg"><i class="fa fa-plus"
            aria-hidden="true"></i> Raise Complaint</button>


      </div>
      <!-- <h3 class="title">About me</h3> -->

      <div class="row">
        <div class="col-md-12">
          <h4 class="title text-center">Complaint Status</h4>
          <div class="card" style="box-shadow: none !important;">
            <!-- <div class="card-header">Previous Issues</div> -->
            <div class="card-body">
              <pagination-controls *ngIf="issues.length" id="foo" class="text-center"
                (pageChange)="p = $event; getIssues()">
              </pagination-controls>
              <p *ngIf="!issues.length" class="text-center">No issues found</p>
              <div class="card custom " *ngFor="
                  let issue of issues
                    | paginate
                      : {
                          id: 'foo',
                          itemsPerPage: limit,
                          currentPage: p,
                          totalItems: issuesCount
                        }; let i = index;
                ">
                <span class="float-right bg-arrow " [ngClass]="{
                  'bg-success text-dark': issue.resolved,
                  'bg-custom text-white': !issue.resolved
                }">

                  <i (click)="
                          toggle('i_' + i + '_body')
                      " class="fas fa-arrow-down  c-pointer  "></i>
                </span>
                <div class="card-header c-pointer shadow" [ngClass]="{
                    'bg-success text-dark': issue.resolved,
                    'bg-custom text-white': !issue.resolved
                  }" (click)="
                  toggle('i_' + i + '_body')">
                  <b>Category:</b> {{ issue.category }}<br />
                  <b>Message:</b> {{ issue.message }}
                  &nbsp; <br>
                  <span class=" badge badge-pill badge-secondary mr-2">{{
                    issue.created_at | date: "short"
                  }}</span>
                  &nbsp;
                  <span class="badge badge-pill " [ngClass]="{
                    'badge-secondary': issue.resolved,
                    'badge-warning': !issue.resolved
                  }">
                    <i class="fa fa-check" *ngIf="issue.resolved" aria-hidden="true"></i>
                    {{ issue.resolved ? "Resolved" : "Pending" }}
                  </span>


                </div>
                <div class="card-body text-left d-none" id="i_{{ i }}_body">
                  <div class="mb-2" *ngFor="let reply of issue.replies">
                    <div class="btn btn-light text-left shadow-sm">
                      <b>Reply ID:</b> {{ reply.reply_id }},
                      <br />
                      <b>Message:</b> {{ reply.reply }}
                    </div>
                    <div class="pl-3 text-muted">
                      {{ reply.reply_at | date: "short" }}
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" ngModel class="form-control" name="reply_{{ issue._id }}"
                      id="reply_{{ issue._id }}" placeholder="Reply" (keyup.enter)="submitReply(issue._id)" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<ng-template #classic let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <button type="button" class="close" (click)="d('Cross click')">
      <i class="now-ui-icons ui-1_simple-remove"></i>
    </button>
    <h4 class="title title-up">Raise Your Complaint</h4>
  </div>
  <div class="modal-body">
    <form #createForm="ngForm" (submit)="submitIssue(createForm)">
      <div class="form-group">
        <label for="category">Category</label>
        <select ngModel class="form-control" name="category" id="category">
          <option [ngValue]="">--select--</option>
          <option *ngFor="let issue of issueList">{{issue}}</option>
          <option>Others</option>
        </select>
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <input ngModel type="text" class="form-control" name="message" id="message" aria-describedby="messagehelpId"
          placeholder="Message" />
        <small id="messagehelpId" class="form-text text-muted">Enter your message</small>
      </div>
      <button class="btn btn-primary btn-block">Submit</button>
    </form>
  </div>
  <!-- <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="c('Close click')">Close</button>
  </div> -->
</ng-template>
