<nav class="navbar navbar-expand-lg bg-primary fixed-top navbar-transparent" color-on-scroll="500">
  <div class="container-fluid">

    <div class="navbar-translate">
      <a class="navbar-brand" [routerLink]="['/']">
        <!-- Eduvantage -->
        <img class="img-fluid" style="height:40px" src="assets/img/edu-logo-wide.png" alt="" [style.borderRadius]="'30px'">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
        (click)="sidebarToggle()">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" data-color="orange">
      <ul class="navbar-nav ml-auto">


        <li class="nav-item" *ngIf="isHomeRoute()">
          <a class="nav-link " routerLink="/home">
            <i class="fas fab fa-home"></i>
            <p class="d-lg-none d-xl-none">Home</p>
          </a>
        </li>
        <!-- <li class="nav-item" *ngIf="isLoggedin ">
          <a class="nav-link " routerLink="/support">
            <i class="now-ui-icons ui-2_settings-90"></i>
            <p>Support</p>
          </a>
        </li> -->
        <li class="nav-item" *ngIf="isLoggedin ">
          <a class="nav-link " routerLink="/profile">
            <i class="now-ui-icons users_circle-08"></i>
            <p>Profile</p>
          </a>
        </li>

        <li class="nav-item " *ngIf="!isLoggedin && isLoginPage()">
          <a rel="tooltip" title="Login" data-placement="bottom" routerLink="/login" class="nav-link" href="/login"><i
              class="fas fab fa-sign-in-alt"></i>
            <p>Login</p>
          </a>
        </li>
        <!-- <li class="nav-item ">
          <a rel="tooltip" title="Download Certificate" data-placement="bottom" routerLink="/download-certificate" class="nav-link">
            <i class="fa fa-download pr-2" aria-hidden="true"></i>
            <p>Download Certificate</p>
          </a>
        </li> -->

        <li class="nav-item" *ngIf="isLoggedin">

          <a class="nav-link c-pointer" rel="tooltip" title="Logout" data-placement="bottom" (click)="logout()">

            <i class="fas fab fa-sign-out-alt    "></i>
            <p>Logout</p>
          </a>
        </li>
      </ul>
      <!-- <p class="d-lg-none d-xl-none" style="    position: fixed;
      bottom: 10px;
      margin: auto;
      width: 100%;
      text-align: center;">Version: {{version}}</p> -->
    </div>

  </div>
</nav>
