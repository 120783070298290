<div class="wrapper">
    <div class="page-header page-header-small" filter-color="orange">
      <div
        class="page-header-image rellax-header"
        data-rellax-speed="-8"
        style="
          background-image: url('https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
        "
      ></div>
      <div class="container">
        <div class="content-center">
          <h3 class="title">Viva Exam</h3>
          <p class="category">Subject Code</p>
        </div>
        <!-- </div> -->
      </div>
      <div class="section">
        <div class="container text-center">
          <div class="button-container">
            <a class="btn btn-primary btn-round btn-lg">
              You will see questions here:
            </a>
          </div>
          <a (click)="getMeetingUrl()" class="btn btn-primary btn-round btn-lg">
            Click here to Join Meeting
          </a>
        </div>
        <div class="row text-black" *ngIf="PracticalQuestions">
          <!-- <div class="col-md-4">
              <webcam [width]="'200'" [height]="'200'"></webcam>
            </div> -->
          <div class="col" *ngIf="!PracticalQuestions.selected_ques">
            <div class="m-2 p-3 bg-secondary rounded"></div>
            <div class="row m-0 p-0">
              <div class="col-5 m-2 p-3 bg-secondary shadow rounded"></div>
              <div class="col-5 m-2 p-3 bg-secondary shadow rounded"></div>
              <div class="col-5 m-2 p-3 bg-secondary shadow rounded"></div>
              <div class="col-5 m-2 p-3 bg-secondary shadow rounded"></div>
            </div>
          </div>
          <div
            class="col text-center mt-5"
            *ngIf="PracticalQuestions.selected_ques"
          >
            <b>
              Questions :
            </b>
            <p [innerHTML]="PracticalQuestions.selected_ques.question"></p>
            <hr />
            <span
              *ngIf="
                PracticalQuestions.selected_ques.regional_available &&
                PracticalQuestions.selected_ques.regional_available == 'Y'
              "
            >
              <p
                [innerHTML]="PracticalQuestions.selected_ques.question_regional"
              ></p>
            </span>
            <b>
              Options :
            </b>
            <ul
              class="list-group mt-2"
              *ngIf="
                PracticalQuestions.selected_ques.options &&
                PracticalQuestions.selected_ques.options.length
              "
            >
              <li
                class="list-group-item py-1"
                *ngFor="let opt of PracticalQuestions.selected_ques.options"
              >
                <p [innerHTML]="opt"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  