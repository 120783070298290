import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'
import { TokenService } from './token.service'
import { AlertService } from './alert.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ts: TokenService,
    private as: AlertService,
  ) {}

  login(data: any) {
    return this.http.post(`${environment.url}/member/login`, data)
  }

  isLoggedIn(): boolean {
    if (this.ts.getToken()) {
      return true
    } else {
      return false
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  logout() {
    // this.cleanUserData();
    const refreshTkn = this.ts.getRefreshToken()
    // console.log(refreshTkn);

    this.http
      .post(`${environment.url}/member/logout`, { refreshTkn })
      .subscribe(
        (resp: any) => {
          // console.log(resp);
          if (resp.success) {
            this.as.successToast(resp.msg)
            this.cleanUserData()
          }
        },
        (err) => {
          this.as.errorToast(err.error.error.message)
          //   this.as.successToast('You are logged out.');
          //   this.cleanUserData();
        },
      )
  }

  cleanUserData() {
    localStorage.removeItem('user')
    localStorage.removeItem('Token')
    localStorage.removeItem('refershToken')
    window.location.reload()
  }
}
