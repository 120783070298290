import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-resister',
  templateUrl: './resister.component.html',
  styleUrls: ['./resister.component.scss']
})
export class ResisterComponent implements OnInit {

  focus;
  focus1;
  constructor(
    private alert: AlertService,
    private router: Router,
    private examService: ExamService
  ) { }

  ngOnInit(): void {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }
  submit(frm: NgForm) {
    if (frm.valid) {
      this.examService.registerMail(frm.value)
        .subscribe((resp: any) => {
          if (resp.success) {
            this.alert.successToast(resp.msg);
            this.router.navigate([resp.url]);
          } else {
            this.alert.errorToast(resp.msg);
          }
        })
    } else {
      this.alert.warningToast('Please fill form completly');
    }
  }
}
