<!-- <div class="fullbg">
    <h1>404</h1>
<p>Oops! Something is wrong.</p>
<a class="button" (click)="homePage()"><i class="icon-home"></i> Go back in home page, is better.</a>
</div> -->
<div class="error-page-wrap">
    <article class="error-page gradient">
        <div style="margin-top: -80px;">
            <h1>404</h1>
            <h2>oops! page not found</h2>
        </div>
        <a (click)="homePage()" title="Back to site" class="error-back">back</a>
    </article>
</div>