import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-viva',
  templateUrl: './viva.component.html',
  styleUrls: ['./viva.component.scss']
})
export class VivaComponent implements OnInit {
  Intervals = [];
  PracticalQuestions: any;

  constructor(private examService: ExamService) {}

  ngOnInit(): void {
    this.Intervals.push(
      setInterval(() => {
        this.examService.getVivaQuestions().subscribe((resp: any) => {
          this.PracticalQuestions = resp;
        });
      }, 5000)
    );
  }

  ngOnDestroy(): void {
    for (const intv of this.Intervals) {
      clearInterval(intv);
    }
  }

  getMeetingUrl() {
    this.examService.getVivaMeetingUrl().subscribe((resp: any) => {
      if (resp.status) {
        window.open(resp.data);
      }else{
        alert('Meeting can\'t be started now')
      }
    });
  }
}
