import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transparent-footer',
  templateUrl: './transparent-footer.component.html',
  styleUrls: ['./transparent-footer.component.scss']
})
export class TransparentFooterComponent implements OnInit {
  data: Date = new Date();
  constructor() { }

  ngOnInit(): void {
  }

}
