<div class="page-header" filter-color="orange">
  <div class="page-header-image"
    style="background-image:url(https://images.unsplash.com/photo-1596496050755-c923e73e42e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1336&q=80)">
  </div>
  <div class="container">
    <div class="col-md-4 content-center">
      <div class="card card-login card-plain">
        <form class="form" #rstpwdForm="ngForm" novalidate autocomplete="off" (submit)="submit(rstpwdForm)">
          <div class="header header-primary text-center">
            <div class="logo-container">
              <img src="assets/img/edu-logo-sq.png" alt="">
            </div>
          </div>
          <div class="content">

            <div class="input-group form-group-no-border input-lg " [ngClass]="{'input-group-focus':focus1===true}">
              <div class="input-group-prepend">
                <span class="input-group-text" (click)="toggleFieldTextType()"><i class="fa " [ngClass]="{
                        'fa-eye-slash': !fieldTextType,
                        'fa-eye': fieldTextType
                      }"></i></span>
              </div>
              <input [type]="fieldTextType ? 'text' : 'password'" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                name="password" placeholder="Enter Password" #password class="form-control" (focus)="focus1=true"
                (blur)="focus1=false" autocomplete="new-password" ngModel required />

            </div>
            <div class="input-group form-group-no-border input-lg " [ngClass]="{'input-group-focus':focus1===true}">
              <div class="input-group-prepend">
                <span class="input-group-text"> <i class="now-ui-icons objects_key-25"></i> </span>
              </div>
              <input type="password" name="confirm_password" placeholder="Retype password" #cnfPassword
                class="form-control" (focus)="focus1=true" (blur)="focus1=false" autocomplete="new-password" ngModel
                required />

            </div>

          </div>
          <div class=" text-center">
            {{rstpwdForm.form.invalid}}
            <button type="button" class="btn btn-primary btn-round btn-lg btn-block"
              [disabled]="rstpwdForm.form.invalid" (click)="submit(rstpwdForm)">Create
              password</button>
          </div>
          <div class="pull-left">
            <h6>
              (This account will be used during your entire online examination process, Please do not share password
              with any one. Password should be of minimum 8 characters containing atleast one capital and a numeric
              number)
            </h6>

          </div>

        </form>
      </div>
    </div>
  </div>
  <app-transparent-footer></app-transparent-footer>
</div>
