<footer class="footer ">
  <div class="container">

    <div class="copyright">
      &copy;
      {{data | date: 'yyyy'}}, Design & Developed by

      <a href="javascript:void(0)" target="_blank">Candidate Portal</a>.
    </div>
  </div>
</footer>
