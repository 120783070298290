import { HttpEventType } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import * as Rellax from 'rellax'
import { AlertService } from 'src/app/services/alert.service'
import { ExamService } from 'src/app/services/exam.service'
import { TokenService } from 'src/app/services/token.service'
import { environment } from 'src/environments/environment'
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router'
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  zoom: number = 14
  lat: number = 44.445248
  lng: number = 26.099672
  styles: any[] = [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }, { lightness: 17 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 18 }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 16 }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#dedede' }, { lightness: 21 }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
    },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{ color: '#fefefe' }, { lightness: 20 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
    },
  ]
  data: Date = new Date()
  focus
  focus1

  subjects: any
  examUrl = environment.examurl
  user: any
  copyUpload: any
  paperFile: any
  paperFileResponse: any
  serverPaperFile: any
  eligibility: any
  isLoggedin = true
  constructor(
    private examService: ExamService,
    private as: AlertService,
    public tokenService: TokenService,
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header')

    var body = document.getElementsByTagName('body')[0]
    body.classList.add('profile-page')
    var navbar = document.getElementsByTagName('nav')[0]
    navbar.classList.add('navbar-transparent')
    this.isLoggedin = this.auth.isLoggedIn()
    if (this.isLoggedin) {
      this.fetchDashboardSummary()
    } else {
      this.router.navigate(['/login'])
    }
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0]
    body.classList.remove('profile-page')
    var navbar = document.getElementsByTagName('nav')[0]
    navbar.classList.remove('navbar-transparent')
  }

  fetchDashboardSummary() {
    this.examService.dashboardSummary().subscribe(
      (resp: any) => {
        if (resp.success) {
          this.eligibility = resp.data.eligiblemenber
          // if (this.eligibility > 60) {
          this.subjects = resp.data.subjects.filter(
            (o) => o.paperType == 'Theory',
          )
          this.user = resp.data.user
          // } else {
          //   this.as.warningToast("Your attendance is below to 60%");
          // }
        } else {
          this.as.errorToast('Failed to fetch data')
        }
      },
      (err: any) => {
        this.as.errorToast('Server Error!')
      },
    )
  }

  getName(subcode: any) {
    switch (subcode) {
      case this.user.paper_code_1
        ? this.user.paper_code_1.trim()
        : this.user.paper_code_1:
        return this.user.paper_name_1

      case this.user.paper_code_2
        ? this.user.paper_code_2.trim()
        : this.user.paper_code_2:
        return this.user.paper_name_2

      case this.user.paper_code_3
        ? this.user.paper_code_3.trim()
        : this.user.paper_code_3:
        return this.user.paper_name_3

      case this.user.paper_code_4
        ? this.user.paper_code_4.trim()
        : this.user.paper_code_4:
        return this.user.paper_name_4

      case this.user.paper_code_5
        ? this.user.paper_code_5.trim()
        : this.user.paper_code_5:
        return this.user.paper_name_5

      case this.user.paper_code_6
        ? this.user.paper_code_6.trim()
        : this.user.paper_code_6:
        return this.user.paper_name_6

      default:
        return 'NA'
    }
  }

  extractFileName(name: String) {
    return name.split('\\').pop()
  }

  selectFile(e: any) {
    if (e.target.files.length) {
      this.paperFile = e.target.files[0]
    } else {
      this.paperFile = null
    }
  }

  uploadPaper() {
    const fileLabel: any = document.getElementById('uploadProgress')
    this.examService.uploadOfflineExamFile(this.paperFile).subscribe((resp) => {
      if (resp.type == HttpEventType.UploadProgress) {
        // console.log(resp);
        fileLabel.style.width =
          Math.round((resp.loaded * 100) / resp.total) + '%'
      } else if (resp.type == HttpEventType.Response) {
        // const body: any = resp.body;
        const body: any = resp.body
        if (body.success) {
          alert(body.msg)
          this.setcopyUpload()
        } else {
          alert(body.msg)
        }
      }
    })
  }

  setcopyUpload() {
    this.examService.getuploadOfflineExamFile().subscribe(
      (resp: any) => {
        if (resp.success) {
          this.paperFileResponse = resp.questions
          this.copyUpload = resp.paperCode
        } else {
          this.as.errorToast(resp.msg)
        }
      },
      (err: any) => {
        this.as.errorToast('Server Unavailable!')
      },
    )
  }

  moveToExam(code) {
    this.examService.isQpExam(code).subscribe(
      (resp: any) => {
        if (resp.success) {
          if (!resp.status) {
            const examUrlWithToken = this.examUrl + '/' + resp.token
            window.open(examUrlWithToken, '_blank')
          } else {
            alert('Exam already submitted ')
            return
          }
        } else {
          this.as.errorToast(resp.msg)
        }
      },
      (err: any) => {
        this.as.errorToast('Server Unavailable!')
      },
    )
  }
}
